const groq = require("groq");

const redirects = groq`*[_type == "redirect"]`;

const slugQuery = groq`
  'slug': content.main.slug.current
`;

const asset = groq`{
  _type,
  _key,
  alt,
  caption,
  '_id': image.asset->_id,
  'dimensions': image.asset->metadata.dimensions,
  'url': image.asset->url,
}`;

const meta = groq`
  ..., 
  "ogImage": openImage.asset->.url,
  "twitterImage": twitterImage.asset->.url
`;

const moduleQuery = groq`
  _type == 'heroSection' => {
    ...,
    main {
      ...,
      Video {
        ...,
        "url": asset->url
      },
      trustpilotImage {
        ...,
        "url": asset->url
      }
    }
  },
  _type == 'videosRow' => {
    ...,
    firstVideo {
      ...,
      "url": asset->url
    },
    secondVideo {
      ...,
      "url": asset->url
    }
  },
  _type == 'twoImagesRow' => {
    ...,
  },
  _type == 'announcementBar' => {
    ...,
    firstColumnImage {
      ...,
      "url": asset->url
    },
    secondColumnImage {
      ...,
      "url": asset->url
    }
  },
  _type == 'nestedPages' => {
    ...,
    page[] {
      ...,
      linkedPage->
    }
  },
  _type == 'productGrid' => {
    ...,
    "products": shopifyProducts[]-> {
      ...,
    }
  },
  _type == 'bestSellingProducts' => {
      ...,
      "product": shopiyfProduct->,
      image {
        ...,
        "alt": asset->altText
      }
  },
  _type == 'productList' => {
    ...,
    products[]-> {
      ...,
    },
    "products": productsShopify[] -> {
      ...,
    }
  },
  _type == 'boughtTogether' => {
    ...,
    products[]-> {
      ...,
    },
    "products": productsShopify[] -> {
      ...,
    }
  },
  _type == 'headingSection' => {
    ...,
    main {
      ...,
      modelFile {
        ...,
        "url": asset->url
      },

      video {
        ...,
        "url": asset-> url
      },
      arModelFile {
        ...,
        "url": asset->url
      }
    }
  },
  _type == 'modelSection' => {
    ...,
    modelFile {
      ...,
      "url": asset->url
    },
    arModelFile {
        ...,
        "url": asset->url
      }
  },
  _type == 'testimonialList' => {
    ...,
    list[] {
      ...,
      video {
        ...,
        "url": asset->url
      }
    }
  },
  _type == 'productListDescription' => {
    ...,
    products[] {
      ...,
      product->,
      "product": productsShopify->
    }
  },
  _type == 'bestSellingProductsSlider' => {
    ...,
    products[] {
      ...,
      product->, 
      "product": shopiyfProduct->,
      image {
        ...,
        "alt": asset->altText
      }
    }
  },
  _type == 'productWithFaqs' => {
    ...,
    product->,
    "product": productsShopify->
  },
  _type == 'productInformations' => {
    ...,
    "product": productShopify-> {
      ...,
      content {
        ...,
        shopify {
          ...,
          variants[]->
        },
        main {
          ...,
          mobileGallery[] {
            ...,
            "alt": asset->altText
          },
          mainImage {
            ...,
            firstAdditionalImage {
              ...,
              "alt": asset->altText
            },
            secondAdditionalImage {
              ...,
              "alt": asset->altText
            },
            thirdAdditionalImage {
              ...,
              "alt": asset->altText
            },
            image {
              ...,
              "alt": asset->altText
            }
          },
          iconListTop {
            ...,
            list[] {
              ...,
              icon {
                ...,
                "alt": asset->altText
              }
            }
          },
          iconListBottom {
            ...,
            list[] {
              ...,
              icon {
                ...,
                "alt": asset->altText
              }
            }
          },
          productGallery[] {
            ...,
            "alt": asset->altText
          },
        }
      }
    }
  },
  _type == 'textIcon' => {
    ...,
    'okendoReviews': reviews.productShopify-> {
      "rating" :content.main.okendoReviews.rating, 
      "totalReviews": content.main.okendoReviews.totalReviews 
     },
     icons {
      ...,
      list[] {
        ...,
        image {
          ...,
          "alt": asset->altText
        }
      }
     },
     image {
      ...,
      image {
        ...,
        "alt": asset->altText
      }
     }
  },
  _type == 'imageRowSection' => {
    ...,
    main {
      ...,
      rows[] {
        ...,
        'okendoReviews': enableReviews.productShopify-> {
            "rating": content.main.okendoReviews.rating,
            "totalReviews": content.main.okendoReviews.totalReviews
        },
        image {
          ...,
          "alt": asset->altText
        },
        iconListTop {
          ...,
          list[] {
            ...,
            icon {
              ...,
              "alt": asset->altText
            }
          }
        },
        firstAdditionalImage {
          ...,
          "alt": asset->altText
        },
        secondAdditionalImage {
          ...,
          "alt": asset->altText
        }
      }
    }
  },
  _type == 'listImage' => {
    ...,
    'okendoReviews': reviews.productShopify-> {
      "rating" :content.main.okendoReviews.rating, 
      "totalReviews": content.main.okendoReviews.totalReviews 
    },
    image {
      ...,
      "alt": asset->altText
    }    
  },
  _type == "textVideoIcons" => {
    ...,
    icons {
      ...,
      list[] {
        ...,
        image {
          ...,
          "alt": asset->altText
        }
      }
    }
  },
  _type == "testimonialCards" => {
    ...,
    list[] {
      ...,
      image {
        ...,
        "alt": asset->altText
      }
    },
    iconListTop {
      ...,
      list[] {
        ...,
        icon {
          ...,
          "alt": asset->altText
        }
      }
    }
  }
`;

const pageQuery = groq`
  ${slugQuery},
  ...,
  'modules': content.main.modules[] {
    ...,
    ${moduleQuery}
  }, 
  "meta": content.meta {
      ${meta},
  },
`;


const blogQuery = groq`
  ${slugQuery},
  ...,
  'modules': content.main.modules[] {
    ...,
    ${moduleQuery}
  }, 
  "meta": content.meta {
      ${meta},
  },
`;

const collectionQuery = groq`
  ${slugQuery},
  ...,
  'modules': content.main.modules[] {
    ...,
    ${moduleQuery}
  }, 
  "meta": content.meta {
      ${meta},
  },
`;

const productQuery = groq`
  ${slugQuery},
    "meta": content.meta {
      ${meta},
    },
  ...,
  'modules': content.main.modules[] {
    ...,
    ${moduleQuery}
  },
  content {
    ...,
    shopify {
      ...,
      variants[]-> {
        ...,
      }
    }
  },
  'main': content.main {
    ...,

    mainImage {
      ...,
      image {
        ...,
        "url": asset->url,
        "alt": asset->altText
      },
      firstAdditionalImage {
        ...,
        "alt": asset->altText
      },
      secondAdditionalImage {
        ...,
        "alt": asset->altText
      },
      thirdAdditionalImage {
        ...,
        "alt": asset->altText
      }
    },
    announcementBarProduct{
      ...,
    },

    productUpsells {
      ...,

      productList[] {
        ...,
        product->,
        "product": productsShopify->
      }
    },

    mobileGallery[] {
      ...,
      _type == 'videoWithThumbnail' => {
        ...,
        "thumbnail": poster.asset->url,
        "url": video.asset->url
      },
      _type == 'image' => {
        ...,
        "url": asset->url,
        "alt": asset->altText
      },
    },
    productGallery[] {
      ...,
      _type == 'model' => {
        ...,
        "url": asset->url
      },

      _type == 'videoWithThumbnail' => {
        ...,
        "thumbnail": poster.asset->url,
        "url": video.asset->url
      },

      _type == 'galleryRow' => {
        ...,
        options[] {
          ...,
          _type == 'model' => {
            ...,
            "url": asset->url
          },
          _type == 'videoWithThumbnail' => {
            ...,
            "url": asset->url
          }
        } 
      },

      _type == 'image' => {
        ...,
        "url": asset->url,
        "alt": asset->altText
      },

      _type == 'video' => {
        ...,
        "url": asset->url
      }, 

      _type == 'blogCategories' => {
        title, 
        subtitle, 
        ...,
      }, 

      _type == 'blogGroup' => {
        ...,
      },
    },
    iconListTop {
      ...,
      list[] {
        ...,
        icon {
          ...,
          "alt": asset->altText
        }
      }
    },
    iconListBottom {
      ...,
      list[] {
        ...,
        icon {
          ...,
          "alt": asset->altText
        }
      }
    },
    announcementBarProduct {
      ...,
      firstColumnImage {
        ...,
        "alt": asset->altText
      },
      secondColumnImage {
        ...,
        "alt": asset->altText
      },
    },

    productBundles[] {
      ...,
      _type == 'reference' => @->

      // bundleUpsell {
      //   ...,
  
      //   productList[] {
      //     ...,
      //     product->
      //   }
      // },
    },

    productQuantity[] {
      ...
    },

    productCases[] {
      ...,
      _type == 'reference' => @->
    }, 
    "productCases": shopifyProductCases[] {
      ...,
      _type == 'reference' => @->
    }
  }, 
`;
module.exports.collections = groq`*[_type == "collection"] {
  ${pageQuery}
}`;
module.exports.blogs = groq`*[_type == "blog"] {
  ${blogQuery}
}`;
module.exports.pages = groq`*[_type == "page"] {
  ${pageQuery}
}`;

module.exports.products = groq`*[_type == "product" && !(_id in path("drafts.**"))]{
  ${productQuery}
}`;
module.exports.productsShopify = groq`*[_type == "productsShopify" && !(_id in path("drafts.**"))]{
  ${productQuery}
}`;

module.exports.siteGlobal = groq`*[_type == "siteGlobal"][0] {
  metaInformation {
    ...,
    openImage {
      "url": asset->url
    },
    favicon {
      "url": asset->url
    }
  }
}`;

module.exports.cartQuery = groq`*[_type == "cart"]{
  freeShippingMin,
  noFreeShipping,
  textCopies,
  emptyButtonText,
  emptyButtonLink,
  upsellProducts[]-> {
      ...,
  }, 
  "upsellProducts": upsellShopifyProducts[]-> {
    ...,
  }

}`;

module.exports.mobileMenuQuery = groq`*[_type == "mobileMenu"]`;

module.exports.menusQuery = groq`*[_type == "menus"]`;

module.exports.notificationBarQuery = groq`*[_type == "notificationBar"]`;

module.exports.returnMoneyBackQuery = groq`*[_type == "returnMoneyBack"]`;

module.exports.socialIconsQuery = groq`*[_type == "social"]`

module.exports.selectCountryQuery = groq`*[_type == "selectCountry"][0]`;

module.exports.pageQuery = pageQuery;
module.exports.productQuery = productQuery;
module.exports.redirects = redirects;
module.exports.blogQuery = blogQuery;
module.exports.collectionQuery = collectionQuery;
